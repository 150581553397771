import React, { Component } from "react";
import axios from "axios";

export default class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      serverState: {
        submitting: false,
        status: null
      }
    };
  }
  render() {

    const handleServerResponse = (ok, msg, form) => {
      this.setState({
        serverState: {
          submitting: false,
          status: { ok, msg }
        }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      this.setState({
        serverState: {
          ...this.state.serverState,
          submitting: true,
        }
      });
      axios({
        method: "post",
        url: "https://formspree.io/f/mdopwzag",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Mensaje enviado, gracias!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };

    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Contacto</h2>
          </div>
          <form
            onSubmit={handleOnSubmit}
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <div>
              <label>
                Tu nombre: <input type="text" name="name" required />
              </label>
            </div>
            <div>
              <label>
                Tu email: <input type="email" name="email" required />
              </label>
            </div>
            <div>
              <label>
                Mensaje: <textarea name="message" required></textarea>
              </label>
            </div>
            <div>
              <button type="submit">Enviar</button>
            </div>
            {this.state.serverState.status && (
              <p className={!this.state.serverState.status.ok ? "errorMsg" : ""}>
                {this.state.serverState.status.msg}
              </p>
            )}
          </form>
        </div>
      </div>
    );
  }
}
