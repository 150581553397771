import React from "react";
import Img from "gatsby-image";
import image from '../images/lorena.jpeg';

const Header = ({ data }) => (
  <div className="about section" id="About">
    <div className="container">
      <div className="about-main row">
        <div className="left col-md-5 col-lg-4 mb-3">
          <img src={image}></img>
        </div>
        <div className="left col-md-7 col-lg-8">
          <div className="about-details">
            <span className="name">Mi nombre es {data.name}.</span>
            <h2 className="sub-position">
              Soy {data.designation} colegiada Nº AO07337.
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html
              }}
            />
            <ul className="details">
              <li>
                <strong>Nombre</strong>
                <p>{data.name}</p>
              </li>
              <li>
                <strong>Ciudad</strong>
                <p>{data.location}</p>
              </li>
              <li>
                <strong>Email</strong>
                <p>
                  <a href={`mailto:${data.gmail}`}>{data.gmail}</a>
                </p>
              </li>
              <li>
                <strong>
                  <a style={{color : "black"}}
                    href={data.doctoralia}
                    target="_blank"
                    rel="noopener noreferrer"
                  >Perfil en Doctoralia</a>
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
